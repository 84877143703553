import { Box } from 'grommet';
import React, { FC } from 'react';
import Block from './block';
import Intro from './intro';
import List from './list';

interface Item {
    item: string;
}

interface props {
    heading: string;
    intro: string;
    items: Item[];
}

const ListBlock: FC<props> = ({ heading, intro, items }) => {
    const list = items.length >= 1 ? items.map(item => ({ title: item.item })) : [];

    return (
        <Box background="light-2" className="light-2">
            <Block direction="column" background="light-2">
                <Intro heading={heading} intro={intro} />
                <List items={list} />
            </Block>
        </Box>
    );
};

export default ListBlock;
